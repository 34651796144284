import React from 'react';

const Back = (props) => {
  return (
    <div>
        {props.isStart > 0
            ? <video autoPlay loop muted className="back"><source src='back/start-back.mp4'/></video>
            : <video autoPlay loop muted className="back"><source src='back/rest-back.mp4'/></video>
        }
    </div>
  )
}

export default Back
